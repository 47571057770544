


export class BrowserAppEvaluationToolInfo {

  public version = '2024.1.22.5';

  constructor() {
  }

}
