// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: 'Accenture: Facilities',
  filename: 'environment.facilities-41434e.ts',

  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: true,
    usernamePassword: true
  },


  firebaseConfig: {
    apiKey: "AIzaSyC5gXlxckVFClpI3ZEgsZXkud0X-G7vfaM",
    appId: "1:903395852498:web:0cd1afc6308c0b364aad0b",
    authDomain: "facilities-41434e.firebaseapp.com",
    databaseURL: "https://facilities-41434e-default-rtdb.europe-west1.firebasedatabase.app",
    messagingSenderId: "903395852498",
    projectId: "facilities-41434e",
    storageBucket: "facilities-41434e.appspot.com",
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-41434e.cloudfunctions.net',

  // nocoDbConfig: {
  //   // httpServer: 'http://localhost:8080',
  //   // httpServer: 'http://spot.local:8080',
  //   httpServer: 'https://mobilitymojo-spot.com',
  //   user: 'test@mobilitymojo.com',
  //   password: 'test@mobilitymojo.com',
  //   authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
  // },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.facilities,
    canCommentOnClusters: false,
    canEditClusters: false,


  },



};
