import {IAppQuestion, AppQuestion} from "./AppQuestion";
import {ValueObject} from "./ValueObject";
import { QuestionKey } from "./QuestionKey";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";
import {environment} from "../../environments/environment";


export class AppQuestionSet extends ValueObject<IAppQuestion[]> {

  private _log: ILogger = LoggerFactory.build( 'QuestionSet' );

  public questionByKey: {[key: QuestionKey]: AppQuestion} = {};
  public questions: AppQuestion[] = [];
  private _questionsByNocoDbId: {[nocoDbId: number]: AppQuestion} = null;

  public static joinValues(a: IAppQuestion[], b: IAppQuestion[] ): IAppQuestion[] {

    const questionByKey: {[key: QuestionKey]: IAppQuestion} = {};

    for( const question of a ) {

      questionByKey[question.key] = question;
    }

    for( const question of b ) {

      questionByKey[question.key] = question;
    }

    return Object.values( questionByKey );
  }

  private _getQuestionByNocoDbId() {

    if( !this._questionsByNocoDbId ) {
      this._questionsByNocoDbId = {};

      for( const question of this.questions ) {
        if( question.value.nocoDbId ) {
          this._questionsByNocoDbId[question.value.nocoDbId] = question;
        }
      }
    }
    return this._questionsByNocoDbId;
  }

  public getQuestionByNocoDbId( nocoDbId: number ): AppQuestion|null {

    const questionsByNocoDbId = this._getQuestionByNocoDbId();
    return questionsByNocoDbId[ nocoDbId ];
  }


  public getQuestionById( id: string|number ): AppQuestion|null {

    if( null == id ) {

      return null;
    }

    if( 'number' === typeof id ) {

      const idAsNumber = id as number;
      return this._getQuestionByNocoDbId()[idAsNumber];
    }

    {
      const idAsNumber: number = Number.parseInt( id );
      if (!Number.isNaN(idAsNumber)) {

        return this._getQuestionByNocoDbId()[idAsNumber];
      }
    }

    return this.questionByKey[id];
  }



  public getMaximumScore( keys: QuestionKey[] ) {

    let accumulator = 0;

    for( const key of keys ) {

      const question = this.questionByKey[key];
      if( !question ) {

        this._log.warn( '!question; question not found', 'key', key );

      } else {

        accumulator += question.value.maximumScore;
      }
    }

    return accumulator;
  }


  protected onSetValue(value: IAppQuestion[] | null) {

    this.questionByKey = {};
    this.questions = [];

    for( const questionValue of value ) {

      const question = new AppQuestion( questionValue );
      this.questions.push( question );
      this.questionByKey[questionValue.key] = question;
    }

  }

  constructor( value: IAppQuestion[] ) {

    super(value);
    this.value = value;
  }




}

